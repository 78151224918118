import React from "react";
import styled from "styled-components";

import {StaticImage} from "gatsby-plugin-image";
import {constants} from "../theme";
import {WrapWidth} from "./ImgConstants";
import {AnimatedLink} from "../components/Links";




const ImgWrapper = styled.div`
width:100%;
display:flex;
justify-content:center;
position:relative;

  @media (max-width: ${constants.breakpoints.lg}) {
    height:50vh;

    
  }

.gatsby-image-wrapper .gatsby-image-wrapper-constrained{
transition:all .2s ease;
overflow:hidden;
 left: 50%;
  position: absolute !important;
  top: 50%;
  transform: translate(-50%, -50%);

  
  }
    

`

export const Jadore = styled(AnimatedLink)`
z-index:9999;
position:relative;
display:flex;
justify-content:center;
padding-top:5%;
padding-bottom:5%;
cursor:pointer;
transition:all .3s ease;

svg{
width: 80vw;
}

     .cls-1 {
     transition:all .3s ease;
     fill: var(--textNormal);
     fill-rule: evenodd;
      }
      
      
      :hover{
      .cls-1 {
     // fill: var(--gold);
      }
      }
     
`
